import React, { Component } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Services extends Component {
  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)

    var servi = window._my_servicios.filter(p => p.link === this.props.match.params.id);
    if(servi.length===0){
      this.props.history.push('/');
    }
  }

  componentWillUpdate () {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  componentDidUpdate () {
    var servi = window._my_servicios.filter(p => p.link === this.props.match.params.id);
    if(servi.length===0){
      this.props.history.push("../"+this.props.match.params.id);
    }
  }

  render () {
    return (
      <div className="bg_morado">
        <div className="max_width padding_width">
          {
            window._my_servicios.filter(p => p.link === this.props.match.params.id).map((item, i) =>
              <div>
                <img src={`${window.rutas}${item.imagen}`} className="img_libro" alt={item.titulo} title={item.titulo}/>
                <h9>{item.titulo}</h9>
                <br/><br/>
                <div dangerouslySetInnerHTML={{ __html: item.descripcion }} className="at_j black"/>
              </div>
            )
          }
          <div id="cl"></div>
        </div>
      </div>
    )
  }
}

export default Services;