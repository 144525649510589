import React, { Component } from 'react';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class About extends Component {
  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  render () {
    return (
      <div>
        <div className="bg_azulin">
          <div className="max_width padding_width">
            <img src="img/emma.jpg" className="img_libro" alt="Emma Picasso" title="Emma Picasso"/>
            <div className="texto_libro">
              <h8>Acerca de mí</h8>
              <br/><br/>
              <div className="at_j black">
                Me llamo Emma Picasso, soy mexicana con raíces peruanas. Desde muy joven desarrollé interés especial por el área de la salud.
                <br/><br/>  
                Para mi la salud significa bienestar en todos los aspectos.  Un delicado balance entre lo físico, emocional y espiritual.
                <br/><br/>
                Me gusta el estilo de vida saludable. Creo que comer de manera saludable, hacer actividad física y sentirnos bien con lo que hacemos son pilares fundamentales de la salud. La etapa de la niñez es fundamental para establecer hábitos saludables. Me gusta trabajar en conjunto con las familias para lograr establecerlos.
                <br/><br/>
                Mi área de especialidad son las hormonas. El sistema endocrinológico es el sistema del cuerpo que abarca las glándulas y su secreción (las hormonas). Ellas envían señales a todo nuestro cuerpo para comunicar algún mensaje.
                <br/><br/>
                Me encanta pensar que su secreción perfectamente coordinada orquesta muchas de las funciones de nuestro cuerpo, por ejemplo la activación de la pubertad. O que la influencia de la luz es tan importante que regula varios ciclos hormonales, como el del cortisol.
              </div>
            </div>
            {/*
            <img src="img/emma.jpg" className="img_media" data-aos="fade-right" alt="Emma Picasso"/>
            <div className="text_media mar_l40 at_j black" data-aos="fade-left">
              <h8>Acerca de mí</h8>
              <br/><br/>
              Me llamo Emma Picasso, soy mexicana con raíces peruanas. Desde muy joven desarrollé interés especial por el área de la salud.
              <br/><br/>  
              Para mi la salud significa bienestar en todos los aspectos.  Un delicado balance entre lo físico, emocional y espiritual.
              <br/><br/>
              Me gusta el estilo de vida saludable. Creo que comer de manera saludable, hacer actividad física y sentirnos bien con lo que hacemos son pilares fundamentales de la salud. La etapa de la niñez es fundamental para establecer hábitos saludables. Me gusta trabajar en conjunto con las familias para lograr establecerlos.
              <br/><br/>
              Mi área de especialidad son las hormonas. El sistema endocrinológico es el sistema del cuerpo que abarca las glándulas y su secreción (las hormonas). Ellas envían señales a todo nuestro cuerpo para comunicar algún mensaje.
            </div>
            */}
            <div id="cl"></div>
          </div>
        </div>

        <div className="bg_morado">
          <div className="max_width padding_width">
            <center>
              <h55>
                Mi formación como profesional ha tomado 17 años, y el camino por recorrer continúa.
                <br/>
                La actualización continua es una de mis prioridades.
              </h55>
            </center>
            <div id="cl"><br/><br/></div>
            <div className="pa_flex_3 imas3">
              <div className="de_flex_3 black" data-aos="fade-right">
                <center><img src="img/estu_1.svg" alt="Estudios" title="Estudios"/></center>
                <div id="cl"></div>
                <h7>Estudios</h7>
                <br/><br/>
                <b>Carrera de Medicina:</b>
                <ul className="lista">
                  <li>Título de Licenciada en Medicina</li>
                  <li>Universidad Nacional Autónoma de México. Facultad de Estudios superiores Iztacala</li>
                </ul>
                <b>Especialidad: (Pediatra)</b>
                <ul className="lista">
                  <li>Pediatría Médica</li>
                  <li>Hospital Infantil de México Federico Gómez</li>
                  <li>Universidad Nacional Autónoma de México</li>
                </ul>
                <b>Subespecialidad: (Endocrinóloga Pediatra)</b>
                <ul className="lista">
                  <li>Hospital Infantil de México Federico Gómez</li>
                  <li>Universidad Nacional Autónoma de México</li>
                </ul>
              </div>
              <div className="de_flex_3 black" data-aos="fade-up">
                <center><img src="img/estu_2.svg" alt="Áreas de interés y objetivos profesionales" title="Áreas de interés y objetivos profesionales"/></center>
                <div id="cl"></div>
                <h7>Áreas de interés y objetivos profesionales</h7>
                <br/><br/>
                Me especializo en la evaluación y tratamiento de enfermedades hormonales:
                <ul className="lista">
                  <li>Diabetes mellitus</li>
                  <li>Hipotiroidismo</li>
                  <li>Hipertiroidismo</li>
                  <li>Talla baja</li>
                  <li>Alteraciones de la pubertad</li>
                  <li>Síndrome de Turner</li>
                </ul>
                {/*
                Mis principales objetivos profesionales son otorgar una atención profesional de calidad, con calidez y compromiso. Así mismo me interesan los aspectos asistenciales (consulta), docencia (enseñanza)
                */}
                Mis principales objetivos profesionales son:
                <ul className="lista">
                  <li>Otorgar una atención profesional de calidad, con calidez y compromiso</li>
                  <li>Los aspectos asistenciales (consulta)</li>
                  <li>La docencia (enseñanza)</li>
                </ul>
              </div>
              <div className="de_flex_3 black" data-aos="fade-left">
                <center><img src="img/estu_3.svg" alt="Áreas de desarrollo profesional" title="Áreas de desarrollo profesional"/></center>
                <div id="cl"></div>
                <h7>Otras áreas de desarrollo profesional</h7>
                <ul className="lista">
                  <li>Referente Médico como endocrinóloga pediátrica para el servicio de Telemedicina de Médicos Sin Fronteras</li>
                  <li>Vocera de la Asociación Mexicana de Diabetes</li>
                  <li>Miembro de: Sociedad Mexicana de Endocrinología Pediátrica (SMEP)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default About;