import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Accordion, AccordionItem } from 'react-sanfona';

class Header extends Component {
  constructor() {
    super()
    this.state = {
      verMenu: 'ver_no',
      verIco: 'menu.svg',
      activeMen: ''
    }
    this.verMenu = this.verMenu.bind(this)
  }

  activa(e) {
    this.setState({ activeMen: e })
    this.setState({verMenu: 'ver_no', verIco: 'menu.svg'})
  }

  verMenu() {
    if(this.state.verMenu==='ver_no'){
      this.setState({verMenu: 'ver_si', verIco: 'menu_x.svg'})
    }else{
      this.setState({verMenu: 'ver_no', verIco: 'menu.svg'})
    }
  }

  render () {
    return (
      <div>
        <div className="max_width header">
          <Link to="../inicio"><img src={`${window.rutas}img/logo_emma.svg`} className="logo_menu quitan" alt="Emma Picasso" title="Emma Picasso"/></Link>
          <Link to="../inicio"><img src={`${window.rutas}img/logo.svg`} className="logo_menu pon" alt="Emma Picasso" title="Emma Picasso"/></Link>

          <div className="title_con_men mm_margin_m">
            <a href="https://www.doctoralia.com.mx/emma-lucila-picasso-rivera/endocrinologo-pediatrico-pediatra/naucalpan-de-juarez2" target="new">Agenda tu cita</a>
          </div>
          <img src={`${window.rutas}img/con_4.svg`} className="logo_con_men" alt="Horario" title="Horario"/>

          
          <div className="title_con_men mm_margin">
            <a href={window.whats} target="new">{window.telefono}</a>
            <br/>
            <h12>PREVIA CITA</h12>
          </div>
          <img src={`${window.rutas}img/con_1.svg`} className="logo_con_men" alt="Teléfono" title="Teléfono"/>
          <div id="cl"></div>
        </div>
      
        <div className="max_width">
          <div className="linea_menu bg_morado"></div>
        </div>

        <img src={`${window.rutas}img/${this.state.verIco}`} alt="Menú" title="Menú" className="menu_res" height="30" onClick={this.verMenu}/>

        <div className="menu_r" id={this.state.verMenu}>
          <div className="overflow">
            <Link to="../inicio"><img src={`${window.rutas}img/logo_emma.svg`} className="logo_menu" alt="Emma Picasso" title="Emma Picasso"/></Link>
            <div id="cl"></div>
            <ul>
              <li>
                <Link to="../inicio" className="m_blanco" onClick={this.verMenu}>INICIO</Link>
              </li>
              <li>
                <Accordion allowMultiple={false}>
                  <AccordionItem title="SERVICIOS" className="dt">
                    <div id="cl"><br/></div>
                    {
                      window._my_servicios.map((item, i) =>
                        <div>
                          <Link to={`../servicios/${item.link}`} className="dd" onClick={this.verMenu}  key={i}>{item.titulo}</Link>
                          <div id="cl"><br/></div>
                        </div>
                      ) 
                    }
                  </AccordionItem>
                </Accordion>
              </li>
              <li>
                <Link to="../about" className="m_blanco" onClick={this.verMenu}>¿QUIÉN SOY?</Link>
              </li>
            </ul>
            <div id="cl"><br/><br/></div>
          </div>
        </div>

      </div>
    )
  }
}

export default Header;