import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { StickyContainer, Sticky } from 'react-sticky';

import Inicio from './components/Inicio'
import Header from './components/Header'
import Menu from './components/Menu'
import Pie from './components/Pie'
import About from './components/About'
import Services from './components/Services'

class App extends Component {
  render () {
    return (
      <Router>
        <Header />
        <StickyContainer>
          <Sticky>
            {({
              style,
              isSticky,
              wasSticky,
              distanceFromTop,
              distanceFromBottom,
              calculatedHeight
            }) => (
              <header style={style} className="menu_f">
                <Menu />
              </header>
            )}
          </Sticky>
          <Switch>
            <Route exact path="/" component={Inicio} />
            <Route path={window.path+"/about"} component={About} />
            <Route path={window.path+"/servicios/:id"} component={Services} />
            <Route component={Inicio} />
          </Switch>
          <div id="cl"></div>
          <Pie />
        </StickyContainer>
      </Router>
    )
  }
}

export default App;
