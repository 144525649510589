import React, { Component } from 'react';
import { Link } from 'react-router-dom'

/*
var arre_menu = window._my_menu;
arre_menu.sort(function (a, b) {
  return b.value - a.value;
})
*/

class Menu extends Component {
  constructor() {
    super()
    this.state = {
      activeMen: ''
    }
  }

  activa(e) {
    this.setState({ activeMen: e })
  }

  render () {
    return (
      <div className="menu_f">
        <div className="menu_back_f max_width">
          <div className="de_mentor">
            <ul>
              <li>
                <Link to="../about" className="m_negro">¿QUIÉN SOY?</Link>
              </li>
              <li className="dropdown">
                  <div className="m_negro dropbtn">SERVICIOS</div>
                  <div class="dropdown-content">
                    {
                      window._my_servicios.map((item, i) =>
                        <Link to={`../servicios/${item.link}`} className="m_negro sn_bold" key={i}>{item.titulo}</Link>
                      ) 
                    }
                  </div>
              </li>
              <li>
                <Link to="../inicio" className="m_negro">INICIO</Link>
              </li>
            </ul>
            <div id="cl"></div>
            {/*
            <table className="table_men">
              <tr>
                <td><a href={window.whats} target="new">{window.telefono}</a></td>
                <td><center><img src="img/con_1.svg"/></center></td>
              </tr>
              <tr>
                <td><a href={`mailto:${window.correo}`}>{window.correo}</a></td>
                <td><center><img src="img/con_2.svg"/></center></td>
              </tr>
              <tr>
                <td>{window.ubicacion}</td>
                <td><center><img src="img/con_3.svg"/></center></td>
              </tr>
            </table>
            <div id="cl"></div>
            */}
          </div>
          <div className="max_width redes_top">
            {
              window._my_redes.map((item, i) => 
                <a href={item.link} target="_new" className="grises" key={i}><img src={`${window.rutas}${item.imagen}`} alt={item.nombre} title={item.nombre}/></a>
              )
            }
            <div id="cl"></div>
          </div>
        </div>
      </div>
    )
  }
}

export default Menu;