import React, { Component } from 'react';

const hoy = new Date(); 
const ano = hoy.getFullYear();

class Pie extends Component {
  render () {
    return (
      <div className="bg_blanco pie">
      	<div className="max_width padding_pie at_c fz-2">
          <a href={`mailto:${window.correo}`}>{window.correo}</a>
          <br/>
          <a href={window.whats} target="new">Móvil {window.telefono}</a>
          <div id="cl"><br/></div>
          <div className="flex_redes">
            {
              window._my_redes.map((item, i) => 
                <a href={item.link} target="_new" className="de_flex_redes grises" key={i}><img src={`${window.rutas}${item.imagen}`} alt={item.nombre} title={item.nombre}/></a>
              )
            }
          </div>
          <div id="cl"></div>
          © Copyright {ano} endopediapicasso.com.mx
          <div id="cl"></div>
          Diseño Web por
          <div id="cl"></div>
          <a href="http://adsvega.com/" target="new"><img src={`${window.rutas}img/vega.png`} title="Vega" alt="Vega" className="logo_pie"/></a>
        </div>
      </div>
    )
  }
}

export default Pie;