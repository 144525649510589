import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Inicio extends Component {
  componentWillMount () {
    window.H5_loading.show();
    //window.scrollTo(0, 0)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  render () {
    return (
      <div>
        <div className="bg_morado">
          <div className="padding_picaso">
            <div className="max_width fondo_piccaso">

              <div className="left_pica">
                <div className="fz-1">
                  <h5>Dra.</h5>
                  <h6>Emma Picasso</h6>
                </div>
                <br/>
                <div className="h4">Endocrinología Pediátrica</div>
              </div>
              <div id="cl"></div>
              <div className="pa_flex_3">
                <div className="de_flex_3a">
                  {/*<img src="img/fondo_1a.svg" alt="Emma Picasso" title="Emma Picasso"/>*/}
                </div>
                <div className="de_flex_3b">
                  <ul className="lista_trans">
                    <li>Diabetes mellitus en niños</li>
                    <li>Alteraciones del crecimiento</li>
                    <li>Problemas de tiroides</li>
                    <li>Alteraciones de la pubertad</li>
                    <li>Alteraciones de glándula suprarrenal</li>
                  </ul>
                </div>
                <div className="de_flex_3c" data-aos="fade-up">
                  <img src="img/fondo_1.png" alt="Emma Picasso" title="Emma Picasso"/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg_blanco">
          <div className="max_width padding_width">
              <img src="img/endocrinologia.jpg" className="img_libro borde_blanco" alt="Endocrinología Pediátrica" title="Endocrinología Pediátrica" data-aos="fade-right"/>
              <div className="texto_libro">
                <div className="linea bg_azulin"></div>
                <center><h8>¿Qué es la endocrinología pediátrica?</h8></center>
                <div className="linea bg_azulin"></div>
                <br/>
                <div className="at_j">
                  Es la rama de la medicina que se encarga de tratar todos los problemas relacionados con las hormonas en el cuerpo de los niños. 
                  <br/><br/>
                  Las hormonas son químicos que producen las glándulas del cuerpo, su función es enviar mensajes para producir alguna reacción en diferentes partes del cuerpo. Por ejemplo, las hormonas deciden cómo crece y madura un niño. Están prácticamente involucradas en todas los procesos de nuestro organismo. La endocrinología es la ciencia que estudia estas glándulas y los efectos de las hormonas.
                  <br/><br/>
                  La endocrinología pediátrica requiere de adiestramiento especial en condiciones pediátricas relacionadas con el crecimiento y el desarrollo. Los problemas hormonales a menudo están presentes de por vida. Los endocrinólogos pediatras somos especialistas en trastornos hormonales en todas las etapas de la niñez y la adolescencia.
                  <br/><br/>
                  La endocrinología pediátrica se encargan del diagnóstico, tratamiento y supervisión de trastornos hormonales incluyendo los siguientes:
                </div>
                <ul class="lista">
                  <li>Problemas de crecimiento, tales como estatura baja</li>
                  <li>Pubertad precoz o tardía</li>
                  <li>Agrandamiento de las glándulas tiroides (bocio)</li>
                  <li>Subactividad y sobreactividad de la glándula tiroidea</li>
                  <li>Hipofunción o hiperfunción de la glándula pituitaria</li>
                  <li>Hipofunción o hiperfunción de la glándula adrenal</li>
                  <li>Genitales ambiguos/intersexo</li>
                  <li>Disfunción ovárica y testicular</li>
                  <li>Diabetes</li>
                  <li>Bajo contenido de azúcar en la sangre (hipoglicemia)</li>
                  <li>Obesidad</li>
                  <li>Problemas con la Vitamina D (raquitismo, hipocalcemia)</li>
                </ul>
              </div>
          </div>
        </div>

        <div className="fondo_pallarax3">
          <div className="max_width padding_width_chico">
            <div className="linea bg_morado"></div>
            <center><h11>SERVICIOS</h11></center>
            <div className="linea bg_morado"></div>
            <div className="pa_flex_4 margen_medio">
              {
                window._my_servicios.slice(0, 4).map((item, i) => {
                  return(
                    <Link to={`../servicios/${item.link}`} className="de_flex_4 cuadro_az" data-aos="fade-up" key={i}>
                      <img src={item.imagen} className="de_100 grises" alt={item.titulo} title={item.titulo}/>
                      <img src={item.icono} className="cuadro_servi" alt={item.titulo} title={item.titulo}/>
                      <div id="cl"></div>
                      <div className="cudro_az_tex">
                        <div className="h8">{item.titulo}</div>
                        <br/>
                        <div dangerouslySetInnerHTML={{ __html: item.resumen }} />
                        <div id="cl"><br/></div>
                        <div className="verMas">VER MÁS</div>
                        <div id="cl"></div>
                      </div>
                    </Link>
                  )
                })
              }
            </div>
          </div>
          <div className="max_width padding_width_dos at_c">
            <div data-aos="fade-up" className="margen_grande">
              <h10>AGENDA TU CITA YA</h10><br/>
              <h11>Fácil y rápido</h11>
              <div id="cl"><br/><br/></div>
              <a href="https://www.doctoralia.com.mx/emma-lucila-picasso-rivera/endocrinologo-pediatrico-pediatra/naucalpan-de-juarez2" target="new" className="boton_con">AGENDAR</a>
            </div>
          </div>
        </div>

        {/*
        <div className="bg_azulin">
          <div className="max_width padding_width">
            <div className="pa_flex_2">
              <div className="de_flex_2" data-aos="fade-right">
                <img src="img/endrocrinoligia.jpg" className="imas_flex" alt="Marketing Digital" title="Marketing Digital"/>
              </div>
              <div className="de_flex_2" data-aos="fade-left">
                <div className="linea bg_blanco"></div>
                <center><h8>¿Qué es la Endocrinología Pediátrica?</h8></center>
                <div className="linea bg_blanco"></div>
                <div id="cl"><br/><br/></div>
                <div className="cuadro_flex">
                  La pediatría es la rama de la medicina que se encarga del estudio del desarrollo integral del niño, desde que nace hasta su adolescencia.
                  <br/><br/>
                  El médico pediatra se dedica a tratar y diagnosticar a los más jóvenes de la familia, dedicados a su bienestar en la salud y familia.
                  <br/><br/>Adicionalmente, tiene una formación especial para prevenir, tratar enfermedades y lesiones en los niños,así como ayuda a tratar otros problemas que afectan a los niños como lo son los trastornos del desarrollo y problemas de comportamiento, emocionales y sociales.
                </div>
              </div>
            </div>
          </div>
        </div>
        */}
        {/*
        <div className="fondo_pallarax2">
          <div className="max_width padding_width at_c">
            <div data-aos="fade-up" className="margen_grande">
              <h10>¡AGENDA TU CITA YA!</h10><br/>
              <h11>Fácil y rápido</h11>
              <div id="cl"><br/><br/></div>
              <a href="https://www.doctoralia.com.mx/emma-lucila-picasso-rivera/endocrinologo-pediatrico-pediatra/naucalpan-de-juarez2" target="new" className="boton_con">AGENDAR</a>
            </div>
          </div>
        </div>
      */}

        {/*
        <div className="bg_morado">
          <div className="max_width padding_width">
            <div className="pa_flex_2">
              <div className="de_flex_2" data-aos="fade-right">
                <div className="linea bg_blanco"></div>
                <center><h9>¿Qué es la pediatría?</h9></center>
                <div className="linea bg_blanco"></div>
                <div id="cl"><br/><br/></div>
                <div className="cuadro_flex">
                  La pediatría es la rama de la medicina que se encarga del estudio del desarrollo integral del niño, desde que nace hasta su adolescencia.
                  <br/><br/>
                  El médico pediatra se dedica a tratar y diagnosticar a los más jóvenes de la familia, dedicados a su bienestar en la salud y familia.
                  <br/><br/>Adicionalmente, tiene una formación especial para prevenir, tratar enfermedades y lesiones en los niños,así como ayuda a tratar otros problemas que afectan a los niños como lo son los trastornos del desarrollo y problemas de comportamiento, emocionales y sociales.
                </div>
              </div>
              <div className="de_flex_2" data-aos="fade-left">
                <img src="img/endrocrinoligia.jpg" className="imas_flex" alt="Marketing Digital" title="Marketing Digital"/>
              </div>
            </div>
          </div>
        </div>
      */}

      </div>
    )
  }
}

export default Inicio;